<template>
  <div class="guide-container">
    <h1>
      How To Activate / Upgrade Binance Fiat Service
    </h1>

    <br />
    <h2 class="second-colour">
      Overview
    </h2>
    <p>
      Note that this guide relates to the Website Version of Binance, not the
      mobile app version.
    </p>

    <p>
      Activating the fiat service is necessary in order for you to buy
      cryptocurrencies on the Binance exchange. The popup which asks you to
      activate the fiat service appears the first time you try to buy crypto
      with fiat (normal) currency.
    </p>

    <p>
      This tutorial only focusses on steps to activate your fiat service. To
      reach this point you will first need to transfer fiat currency (normal
      money) into your Binance account using either a credit / debit card or
      bank transfer.
    </p>

    <!-- Using target="_blank" to open in a new tab 
    https://stackoverflow.com/a/49654382/15546776 -->
    <p>
      • To use a debit / credit card you can you can follow
      <router-link
        to="/how-to-fund-and-buy-bitcoin-with-a-binance-account"
        target="_blank"
        >this guide</router-link
      >
      from points 1 to 9.
    </p>

    <p>
      • Or for bank transfer you can follow
      <router-link
        to="/how-to-buy-bitcoin-on-binance-with-bank-transfer"
        target="_blank"
      >
        this guide</router-link
      >
      from points 1 to 8.
    </p>

    <br />

    <h2 class="second-colour">
      Steps
    </h2>
    <p>
      1) Once you've deposited money into your Binance account and are about to
      buy cryptocurrency for the first time. You will see this pop up below.
      Click 'Start Now'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToActivateBinanceFiatService/1.jpg"
      alt="Binance upgrade your fiat service pop up notification"
    />

    <p>
      2) Click the checkbox to agree to the 'Terms Of Use' and 'Privacy Policy'.
      Click 'Start'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToActivateBinanceFiatService/2.jpg"
      alt="Binance Activate Fiat Service Terms Of Use and Privacy Policy popup"
    />

    <p>
      3) Click the checkbox to agree to sharing account information. Click
      'Confirm'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToActivateBinanceFiatService/3.jpg"
      alt="Binance agree to sharing account information pop up box"
    />

    <p>
      4) You should then see the 'Activated' pop up. Click 'Ok'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToActivateBinanceFiatService/4.jpg"
      alt="Fiat service activated pop up"
    />

    <br />
    <br />

    <h2 class="second-colour">
      Fiat Service Now Activated
    </h2>

    <p>
      You've now successfully activated the fiat service within your
      Binance account. Next you can continue to purchase cryptocurrencies. To do
      this you can follow these guides:
    </p>
    <p>
      • To use a debit / credit card follow
      <router-link
        to="/how-to-fund-and-buy-bitcoin-with-a-binance-account"
        target="_blank"
        >this guide</router-link
      >
      from point 9 onwards.
    </p>

    <p>
      • Or for bank transfer follow
      <router-link
        to="/how-to-buy-bitcoin-on-binance-with-bank-transfer"
        target="_blank"
      >
        this guide</router-link
      >
      from point 8 onwards.
    </p>
  </div>
</template>
